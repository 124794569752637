@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

html,
body {
    font-family: 'Roboto', sans-serif;
    @apply bg-slate-700;
    @apply w-full h-full;
    @apply text-white;
}

#root {
    @apply w-full h-full;
}