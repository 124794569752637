.download-page {
    font-size: 14pt;
}

.input {
    @apply block mt-2;
    @apply rounded-md w-full;
    @apply border-gray-300 shadow-sm;
    @apply focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
    @apply text-black;

    /*
       For some reason, I can't get focus:ring-opacity-50 to work... There is an
       open issue on Github about this. For now, it'll look a a bit ugly, which
       is fine :)
    */
}

.checkboxInput {
    @apply appearance-none;
    @apply h-4 w-4;
    @apply border border-gray-300 rounded-sm;
    @apply bg-white checked:bg-blue-600 checked:border-blue-600;
    @apply focus:outline-none transition duration-200;
    @apply mt-1 align-top;
    @apply bg-no-repeat bg-center bg-contain; 
    @apply float-left mr-2 cursor-pointer;
    @apply ml-1;
}